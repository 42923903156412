import { useEffect, useState } from "react";
import Sidebar from "../../components/admin/Sidebar";
import "../../static/css/admin/ListaAtletas.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ExportExcel from "../../components/admin/ExcelExport";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";

const ListaAtletas = () => {
  const axiosPrivate = useAxiosPrivate();
  const [atletas, setAtletas] = useState([]);
  const [excelInfo, setexcelInfo] = useState([]);
  const options = [
    { value: "", text: "Año" },
    { value: "2023", text: "2023" },
    { value: "2024", text: "2024" },
  ];
  const [selected, setSelected] = useState(options[0].value);
  const [selection, setSelection] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getInfo = async () => {
      try {
        const response = await axiosPrivate.get("/api/atleta/enrolled/", {
          signal: controller.signal,
        });
        isMounted && setAtletas(response.data.atletasNominal);
      } catch (err) {}
      try {
        const response = await axiosPrivate.get("/api/atleta/excel/", {
          signal: controller.signal,
        });
        isMounted && setexcelInfo(response.data.atletas);
      } catch (err) {}
    };
    getInfo();
  }, [axiosPrivate]);

  const DeleteAtleta = async (id) => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.delete(`/api/atleta/info/${id}/`, {
        signal: controller.signal,
      });
      if (response.status === 200) {
        alert("Atleta eliminado satisfactoriamente.");
        window.location.reload(true);
      } else {
        alert("Ha ocurrido un error.");
      }
    } catch (err) {}
    alert("Atleta eliminado satisfactoriamente.");
    window.location.reload(true);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelected(value);
    let tempArray = [];

    if (value === "") {
      setSelection([]);
    } else {
      for (let i = 0; i < atletas.length; i++) {
        const atleta = atletas[i];
        if (atleta.Ano == value) {
          tempArray.push(atleta);
        }
      }
      setSelection(tempArray);
    }
  };

  return (
    <section className="ListaAtletas">
      <Sidebar />
      <div className="ListaAtletasContent">
        <h1>Listado de Atletas Inscritos</h1>
        <div className="Selector">
          Seleccione un nivel:
          <select value={selected} onChange={handleChange}>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        </div>
        <table>
          <thead>
            <th></th>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>DOB</th>
            <th>Deporte</th>
            <th>Programa</th>
            <th>Nivel</th>
            <th>Club</th>
          </thead>
          <tbody>
            {selection.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.Atleta__Nombre}</td>
                <td>{item.Atleta__Apellido}</td>
                <td>{item.Atleta__DOB}</td>
                <td>{item.Atleta__Deporte}</td>
                <td>{item.Atleta__Programa}</td>
                <td>{item.Atleta__Nivel}</td>
                <td>{item.Club__Nombre}</td>
                <td>
                  <a onClick={() => DeleteAtleta(item.id)}>
                    <PersonRemoveIcon />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <ExportExcel
          excelData={excelInfo}
          fileName={"Listado de Atletas Inscritos"}
        />
      </div>
    </section>
  );
};

export default ListaAtletas;
