import { useState, useEffect } from "react";
import "../../static/css/clubs/AgregarAtletas.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../utils/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
const URL = "/api/atleta/info/";

const EditarAtletasAdmin = () => {
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [info, setInfo] = useState({});
  const [DeporteSelected, setDeporteSelected] = useState("");
  const [ProgramSelected, setProgramSelected] = useState("");
  const [NivelSelected, setNivelSelected] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();
    const getInfo = async () => {
      try {
        const response = await axiosPrivate.get(`/api/atleta/info/${id}`, {
          signal: controller.signal,
        });
        const data = response.data.data;
        setInfo(data);
        setDeporteSelected(data.Deporte);
        setProgramSelected(data.Programa);
        setNivelSelected(data.Nivel);
      } catch (err) {
        console.error(err);
      }
    };
    getInfo();
  }, [axiosPrivate, id]);

  const handleDeporteChange = (event) => {
    setDeporteSelected(event.target.value);
    setProgramSelected("");
    setNivelSelected("");
    setInfo({ ...info, Deporte: event.target.value, Programa: "", Nivel: "" });
  };

  const handleProgramChange = (event) => {
    setProgramSelected(event.target.value);
    setNivelSelected("");
    setInfo({ ...info, Programa: event.target.value, Nivel: "" });
  };

  const handleNivelChange = (event) => {
    setNivelSelected(event.target.value);
    setInfo({ ...info, Nivel: event.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosPrivate.patch(
        URL + id + "/",
        JSON.stringify({
          Nombre: info.Nombre,
          Apellido: info.Apellido,
          DOB: info.DOB,
          Deporte: info.Deporte,
          Programa: info.Programa,
          Nivel: info.Nivel,
        })
      );
      alert("Gimnasta editado correctamente!");
      navigate("/admin/");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="AgregarAtletas">
      <header>
        <p>Editar Gimnasta</p>
      </header>
      <div className="main">
        <form onSubmit={handleSubmit} className="EditForm">
          <label>Nombre</label>
          <input
            name="Nombre"
            type="text"
            className="text-field"
            placeholder="Nombre"
            value={info.Nombre}
            onChange={(e) => setInfo({ ...info, Nombre: e.target.value })}
            required
          />
          <label>Apellido</label>
          <input
            name="Apellido"
            type="text"
            className="text-field"
            placeholder="Primer Apellido"
            value={info.Apellido}
            onChange={(e) => setInfo({ ...info, Apellido: e.target.value })}
            required
          />
          <label>Fecha de Nacimiento</label>
          <input
            name="DOB"
            type="date"
            className="text-field"
            placeholder="DD/MM/YYYY"
            value={info.DOB}
            onChange={(e) => setInfo({ ...info, DOB: e.target.value })}
            required
          />
          <label>Modalidad</label>
          <div className="ProgramSelector">
            <label>
              <input
                type="radio"
                value="Gimnasia Artistica"
                checked={DeporteSelected === "Gimnasia Artistica"}
                onChange={handleDeporteChange}
              />
              Gimnasia Artistica
            </label>
            <label>
              <input
                type="radio"
                value="Gimnasia en Trampolin"
                checked={DeporteSelected === "Gimnasia en Trampolin"}
                onChange={handleDeporteChange}
              />
              Gimnasia en Trampolin
            </label>
          </div>

          {DeporteSelected === "Gimnasia Artistica" && (
            <>
              <label>Programa</label>
              <div className="ProgramSelector">
                <label>
                  <input
                    type="radio"
                    value="USAG"
                    checked={ProgramSelected === "USAG"}
                    onChange={handleProgramChange}
                  />{" "}
                  USAG
                </label>
                <label>
                  <input
                    type="radio"
                    value="XCEL"
                    checked={ProgramSelected === "XCEL"}
                    onChange={handleProgramChange}
                  />{" "}
                  XCEL
                </label>
                <label>
                  <input
                    type="radio"
                    value="UPAG"
                    checked={ProgramSelected === "UPAG"}
                    onChange={handleProgramChange}
                  />{" "}
                  UPAG
                </label>
              </div>
              <label>Nivel</label>
              {ProgramSelected === "USAG" && (
                <select
                  className="text-field"
                  value={NivelSelected}
                  onChange={handleNivelChange}
                  required
                >
                  <option value={``}>Nivel</option>
                  <option value={`Baby Gym`}>Baby Gym</option>
                  <option value={`Little Gym`}>Little Gym</option>
                  <option value={`Future Star`}>Future Star</option>
                  <option value={`Beginner`}>Beginner</option>
                  {Array.from({ length: 10 }, (_, i) => i + 1).map((level) => (
                    <option key={level} value={`Nivel ${level}`}>
                      Nivel {level}
                    </option>
                  ))}
                </select>
              )}
              {ProgramSelected === "XCEL" && (
                <select
                  className="text-field"
                  value={NivelSelected}
                  onChange={handleNivelChange}
                  required
                >
                  <option value="">Nivel</option>
                  <option value="Bronze">Bronze</option>
                  <option value="Silver">Silver</option>
                  <option value="Gold">Gold</option>
                </select>
              )}
              {ProgramSelected === "UPAG" && (
                <select
                  className="text-field"
                  value={NivelSelected}
                  onChange={handleNivelChange}
                  required
                >
                  <option value="">Nivel</option>
                  <option value="9-10">9 y 10 años</option>
                  <option value="11-12">11 y 12 años</option>
                  <option value="13-14">13 y 14 años</option>
                </select>
              )}
            </>
          )}

          {DeporteSelected === "Gimnasia en Trampolin" && (
            <>
              <label>Programa</label>
              <div className="ProgramSelector">
                <label>
                  <input
                    type="checkbox"
                    value="Trampolin"
                    checked={ProgramSelected.includes("Trampolin")}
                    onChange={handleProgramChange}
                  />
                  Trampolin
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="MiniTramp"
                    checked={ProgramSelected.includes("MiniTramp")}
                    onChange={handleProgramChange}
                  />
                  MiniTramp
                </label>
                <label>
                  <input
                    type="checkbox"
                    value="Sincronismo"
                    checked={ProgramSelected.includes("Sincronismo")}
                    onChange={handleProgramChange}
                  />
                  Sincronismo
                </label>
              </div>
              <label>Nivel</label>
              <select
                className="text-field"
                value={NivelSelected}
                onChange={handleNivelChange}
                required
              >
                <option value="">Nivel</option>
                <option value="5-6 años">5-6 años</option>
                <option value="7-8 años">7-8 años</option>
                <option value="9-10 años">9-10 años</option>
                <option value="11-12 años">11-12 años</option>
                <option value="13-14 años">13-14 años</option>
                <option value="15-16 años">15-16 años</option>
                <option value="17-21 años">17-21 años</option>
                <option value="Senior">Senior</option>
              </select>
            </>
          )}
          <input type="submit" className="submit-button" value="Actualizar" />
        </form>
      </div>
    </div>
  );
};

export default EditarAtletasAdmin;
