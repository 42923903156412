import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/admin/Sidebar";
import "../../static/css/admin/Club.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import EditIcon from "@mui/icons-material/Edit";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";

const Club = () => {
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [atletas, setAtletas] = useState([]);
  const [info, setInfo] = useState([]);
  const [staff, setStaff] = useState([]);
  const [pagos, setPagos] = useState([]);
  const [equipos, setEquipos] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getInfo = async () => {
      try {
        const response = await axiosPrivate.get(`/api/admin/club/${id}`, {
          signal: controller.signal,
          params: {
            id: id,
          },
        });
        isMounted && setInfo(response.data.info.clubInfo);
        isMounted && setAtletas(response.data.info.atletasInfo);
        isMounted && setStaff(response.data.info.staffInfo);
        isMounted && setPagos(response.data.info.pagosInfo);
        isMounted && setEquipos(response.data.info.equiposInfo);
      } catch (err) {}
    };
    getInfo();
  }, [axiosPrivate]);

  const DeleteAtleta = async (id) => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.delete(`/api/atleta/info/${id}/`, {
        signal: controller.signal,
      });
      if (response.status === 200) {
        alert("Atleta eliminado satisfactoriamente.");
        window.location.reload(true);
      } else {
        alert("Ha ocurrido un error.");
      }
    } catch (err) {}
    alert("Atleta eliminado satisfactoriamente.");
    window.location.reload(true);
  };

  const DeleteStaff = async (id) => {
    const controller = new AbortController();
    try {
      const response = await axiosPrivate.delete(`/api/staff/info/${id}/`, {
        signal: controller.signal,
      });
      if (response.status === 200) {
        alert("Staff eliminado satisfactoriamente.");
        window.location.reload(true);
      } else {
        alert("Ha ocurrido un error.");
      }
    } catch (err) {}
    alert("Staff eliminado satisfactoriamente.");
    window.location.reload(true);
  };

  return (
    <section className="Club">
      <Sidebar />
      <div className="ClubContent">
        <h1>{info.Nombre}</h1>
        <br></br>
        <h3>Atletas</h3>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Fecha de Nacimiento</th>
              <th>Modalidad</th>
              <th>Programa</th>
              <th>Nivel</th>
            </tr>
          </thead>
          <tbody>
            {atletas.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.Nombre}</td>
                <td>{item.Apellido}</td>
                <td>{item.DOB}</td>
                <td>{item.Deporte}</td>
                <td>{item.Programa}</td>
                <td>{item.Nivel}</td>
                <td>
                  <a href={`/admin/editar-atleta/${item.id}`}>
                    <EditIcon />
                  </a>
                </td>
                <td>
                  <a onClick={() => DeleteAtleta(item.id)}>
                    <PersonRemoveIcon />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h3>Staff</h3>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Pasaporte</th>
              <th>Email</th>
              <th>Telefono</th>
              <th>Rol</th>
            </tr>
          </thead>
          <tbody>
            {staff.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.Nombre}</td>
                <td>{item.Apellido}</td>
                <td>{item.Pasaporte}</td>
                <td>{item.Email}</td>
                <td>{item.Telefono}</td>
                <td>{item.Rol}</td>
                <td>
                  <a onClick={() => DeleteAtleta(item.id)}>
                    <PersonRemoveIcon />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h3>Participación en Equipos</h3>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Programa</th>
              <th>Nivel</th>
            </tr>
          </thead>
          <tbody>
            {equipos.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.Programa}</td>
                <td>{item.Nivel}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <h3>Historial de Pagos</h3>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Monto</th>
              <th>Fecha</th>
              <th>Método</th>
            </tr>
          </thead>
          <tbody>
            {pagos.map((item, index) => (
              <tr key={item.id}>
                <td>{index + 1}</td>
                <td>{item.Monto}</td>
                <td>{item.Fecha}</td>
                <td>{item.Metodo}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};
export default Club;
