import { useRef, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import countryList from "react-select-country-list";
import "../../static/css/public/Register.css";
import axios from "../../utils/axios";
import logo from "../../static/img/public/4.png";
const register_URL = "/api/club/info/";

const Register = () => {
  const navigate = useNavigate();

  const userRef = useRef();
  const errRef = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [Nombre, setClubName] = useState("");
  const [NombreCorto, setClubShortName] = useState("");
  const [Email, setEmail] = useState("");
  const [Telefono, setTelefono] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [Pais, setPais] = useState("");
  const paises = useMemo(() => countryList().getData(), []);
  const [PaisSelected, setPaisSelected] = useState(paises[0].label);

  const handleChangePais = (event) => {
    setPaisSelected(event.target.value);
    setPais(event.target.value);
  };

  useEffect(() => {
    userRef.current.focus();
    localStorage.removeItem("refreshToken");
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [username, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        register_URL,
        JSON.stringify({
          username,
          password,
          Nombre,
          NombreCorto,
          Email,
          Pais,
          Telefono,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setUsername("");
      setPassword("");
      setClubName("");
      setClubShortName("");
      setEmail("");
      alert("Club creado satisfactoriamente!");
      navigate("/login", { replace: true });
    } catch (err) {
      alert(err.response.data.data);
    }
  };

  return (
    <div className="register">
      <div className="register-page">
        <img className="lg" src={logo} alt="Logo" />
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        {/* <h1>Muchas gracias por su interés, las inscripciones para la Copa IGA 2023 ya cerraron. ¡Nos vemos el próximo año!</h1> */}
        <div className="form">
          <h1>Formulario de Creacion de Club para Copa IGA</h1>
          <form onSubmit={handleSubmit} className="EditForm">
            <div className="field">
              <input
                name="username"
                type="text"
                ref={userRef}
                className="text-field"
                placeholder="Nombre de usuario"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="field space">
              <input
                name="password"
                type="password"
                className="text-field"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="field space">
              <input
                name="password"
                type="password"
                className="text-field"
                placeholder="Confirma tu Contraseña"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
            </div>
            <div className="field space">
              <input
                name="email"
                type="email"
                className="text-field"
                placeholder="Email"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="field space">
              <input
                name="telefono"
                type="text"
                className="text-field"
                placeholder="Teléfono"
                value={Telefono}
                onChange={(e) => setTelefono(e.target.value)}
              />
            </div>
            <div className="field space">
              <input
                name="name"
                type="text"
                className="text-field"
                placeholder="Nombre del Club"
                value={Nombre}
                onChange={(e) => setClubName(e.target.value)}
              />
            </div>
            <div className="field space">
              <input
                name="name"
                type="text"
                className="text-field"
                placeholder="Nombre Corto del Club"
                value={NombreCorto}
                onChange={(e) => setClubShortName(e.target.value)}
              />
            </div>
            <select
              className="text-field"
              value={PaisSelected}
              onChange={handleChangePais}
              required
            >
              {paises.map((option) => (
                <option key={option.label} value={option.label}>
                  {option.label}
                </option>
              ))}
            </select>
            <div className="field">
              <input
                type="submit"
                className="submit-button"
                value="Registrar"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Register;
